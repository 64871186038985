form[id^="commerce-checkout-form"] {
  max-width: 100%;

  input {
    max-width: 100%;
    border-sizing: border-box;
  }

  .field-type-addressfield {
    .locality-block {
      display: flex;
      flex-flow: row wrap;
      .form-item {
        float: unset;
      }
    }
  }
}
